import React from "react";
import Loader from "../../components/Loader/Loader";
import PageTitleWidget from "../../components/PageTitle/PageTitleWidget";
import FooterOne from "../../components/Footer/FooterOne";

const TermCondition = () => (
  <Loader>
    <PageTitleWidget title="Terms & Conditions" />
    <section>
      <div className="container">
        <div className="row">
          <div className="col-md-12 terms">
            <h2 className="font-700">Terms and legal conditions</h2>
            These terms and conditions govern your use of the services provided
            by Web Chisel LLC. By using our services, you agree to be bound by
            these terms and conditions. If you do not agree to these terms and
            conditions, please do not use our services. Services Web Chisel LLC
            provides web development services to its clients. These services
            include, but are not limited to, website design and development,
            website maintenance, website hosting, and website security. Payment
            Web Chisel LLC charges for its services based on an agreed-upon
            price with the client. Payment must be made in full before work
            begins. We accept payment through PayPal, credit card, or wire
            transfer. In the event of late payment, we reserve the right to
            charge interest on the outstanding amount at a rate of 1.5% per
            month or the maximum allowed by law. Intellectual Property Web
            Chisel LLC retains the intellectual property rights to any work it
            produces for its clients, including website designs, graphics, and
            software. Clients may use the work produced for them by Web Chisel
            LLC for their own purposes but may not resell, license, or
            distribute the work without our written consent. Confidentiality Web
            Chisel LLC will keep confidential any information it receives from
            clients, including business plans, financial data, and personal
            information, and will not share this information with third parties
            without the client's consent. Limitation of Liability Web Chisel LLC
            is not liable for any damages or losses arising from the use of our
            services, including but not limited to, loss of data, loss of
            revenue, or any other direct, indirect, or consequential damages.
            Our liability is limited to the amount paid by the client for our
            services. Warranty Web Chisel LLC provides a 30-day warranty on any
            work produced for our clients. If there are any defects in our work
            during this time, we will fix them free of charge. This warranty
            does not cover defects caused by changes made to the work by the
            client or third parties. Termination Either party may terminate the
            agreement between Web Chisel LLC and the client at any time with
            written notice. If the client terminates the agreement before the
            work is completed, they will be responsible for payment for any work
            done up to that point. Jurisdiction These terms and conditions are
            governed by the laws of the state of California and any disputes
            arising from them will be settled in the courts of the state of
            California. Amendments Web Chisel LLC reserves the right to modify
            these terms and conditions at any time. The modified terms and
            conditions will be effective immediately upon posting on our
            website. Your continued use of our services after any such
            modifications will constitute your agreement to the modified terms
            and conditions.
          </div>
        </div>
      </div>
    </section>
    <FooterOne />
  </Loader>
);

export default TermCondition;
