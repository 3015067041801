import React from "react";
import Loader from "../../components/Loader/Loader";
import PageTitleWidget from "../../components/PageTitle/PageTitleWidget";
import FooterOne from "../../components/Footer/FooterOne";

const Privacy = () => (
  <Loader>
    <PageTitleWidget title="Privacy Policy" />
    <section>
      <div className="container">
        <div className="row">
          <div className="col-md-12 terms">
            <h2 className="font-700">Privacy Policy</h2>
            <p>
              At Web Chisel LLC, we are committed to protecting your privacy and
              safeguarding your personal information. This Privacy Policy
              explains how we collect, use, and disclose your information when
              you use our website and services.
            </p>
            <h3>Information We Collect When you visit our website</h3>{" "}
            <p>
              We may collect the following information: Personal Information:
              This includes your name, email address, phone number, and other
              contact details that you provide when you sign up for our services
              or contact us.
            </p>
            <p>
              Usage Information: We may collect information about your use of
              our website, including the pages you visit, the links you click,
              and other actions you take while using our website.
            </p>
            <h3>How We Use Your Information</h3>
            <p>We may use your information for the following purposes:</p>{" "}
            <p>
              To provide our services: We use your information to provide and
              improve our services, such as responding to your inquiries,
              providing customer support, and processing payments. To
              communicate with you: We may use your information to send you
              updates about our services, newsletters, and promotional offers.
              To improve our website: We may use your information to analyze how
              our website is used and to improve its functionality and
              performance. To comply with legal obligations: We may use your
              information to comply with applicable laws and regulations, such
              as responding to a subpoena or court order.
            </p>
            <h3>How We Protect Your Information</h3>
            <p>
              We take reasonable measures to protect your personal information
              from unauthorized access, use, or disclosure. We use
              industry-standard security measures such as encryption, firewalls,
              and secure servers to protect your information.
            </p>
            <h3>Disclosure of Your Information</h3>
            <p>
              We may disclose your personal information to third-party service
              providers that help us provide our services. These service
              providers are contractually bound to use your information only for
              the purpose of providing the services we have requested. We may
              also disclose your information if required by law, such as in
              response to a subpoena or court order.
            </p>
            <h3>Third-Party Links</h3>
            <p>
              Our website may contain links to third-party websites. These
              websites have their own privacy policies, and we are not
              responsible for their content or practices.
            </p>
            <h3>Your Choices </h3>
            <p>
              You can choose not to provide us with certain information, but
              this may limit your ability to use some of our services. You can
              also opt-out of receiving promotional emails from us by clicking
              the unsubscribe link at the bottom of our emails.
            </p>
            <h3>Updates to this Privacy Policy</h3>
            We may update this Privacy Policy from time to time. The updated
            Privacy Policy will be posted on our website with the date of the
            last update. We encourage you to review this Privacy Policy
            periodically. <h3>Contact Us</h3>{" "}
            <p>
              If you have any questions or concerns about this Privacy Policy,
              please contact us at privacy@webchisel.net.
            </p>
          </div>
        </div>
      </div>
    </section>
    <FooterOne />
  </Loader>
);

export default Privacy;
