import React from "react";
import Loader from "../../components/Loader/Loader";
import PageTitleContact from "../../components/PageTitle/PageTitleContact";
import ContactFour from "../../components/ContactUs/ContactFour";
import FooterOne from "../../components/Footer/FooterOne";

const ContactCreative = () => {
  return (
    <Loader>
      <PageTitleContact title="Contact Us" tagline="" />
      <ContactFour />
      <FooterOne />
    </Loader>
  );
};

export default ContactCreative;
