import React, { useRef } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

// Home pages
import MinimalDesign from "./pages/home/MinimalDesign";

// Pages
import ContactCreative from "./pages/contact/ContactCreative";

import Page404 from "./pages/others/Page404";
import TermCondition from "./pages/others/TermCondition";
import Privacy from "./pages/others/Privacy";

import ScrollToTop from "./helpers/ScrollToTop";
import HeaderTwo from "./components/Header/HeaderTwo";

function App() {
  const about = useRef(null);
  const service = useRef(null);
  const team = useRef(null);

  return (
    <Router>
      <HeaderTwo about={about} service={service} team={team} />
      <ScrollToTop>
        <Switch>
          <Route exact path="/">
            <MinimalDesign about={about} service={service} team={team} />
          </Route>
          <Route exact path="/contact" component={ContactCreative} />
          <Route exact path="/terms" component={TermCondition} />
          <Route exact path="/privacy" component={Privacy} />
          <Route component={Page404} />
        </Switch>
      </ScrollToTop>
    </Router>
  );
}

export default App;
