import React from "react";
import ContactFormSix from "./ContactFormSix";
import Icofont from "react-icofont";
// import Map from "../Maps/Map";

const ContactFour = () => (
  <>
    <section className="contact-us white-bg" id="contact">
      <div className="container">
        <div className="clearfix">
          <div className="bg-flex-right map-section col-md-6 mt-5 mr-10 rounded-2 overflow-hidden">
            <div className="col col-flex">
              <div className="col">
                <div className="col-inner spacer gradient-bg">
                  <div className="text-center white-color">
                    <Icofont icon="email" className="font-50px white-icon" />
                    <h2>Email Us</h2>
                    <p className="mb-0">contact@webchisel.net</p>
                  </div>
                </div>
              </div>
              <div className="col rounded">
                <div className="col-inner spacer dark-bg">
                  <div className="text-center white-color">
                    <Icofont icon="iphone" className="font-50px white-icon" />
                    <h2>Call Us</h2>
                    <a href="tel:4175450271" className="mb-0">
                      (+1) 417 545-0271
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-about-left col-md-6 text-left">
            <h2>Get in Touch</h2>
            <h4 className="text-uppercase">Find your brand's voice</h4>
            <ContactFormSix />
          </div>
        </div>
      </div>
    </section>
    <section className="p-0 contact-section">
      <div className="container-fluid">
        <div className="row row-flex">
          <div className="col-md-6 rounded">
            <div className="col-inner spacer gradient-bg">
              <div className="text-center white-color">
                <Icofont icon="email" className="font-50px white-icon" />
                <h2>Email Us</h2>
                <p className="mb-0">contact@webchisel.net</p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="col-inner spacer dark-bg">
              <div className="text-center white-color">
                <Icofont icon="iphone" className="font-50px white-icon" />
                <h2>Call Us</h2>
                <a href="tel:4175450271" className="mb-0">
                  (+1) 417 545-0271
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
);

export default ContactFour;
