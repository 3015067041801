import React, { useState, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import FsLightbox from "fslightbox-react";
import Loader from "./../../components/Loader/Loader";
import dataMinimal from "../../data/Slider/minimal-design-data.json";
import HeroSliderCombined from "../../components/Hero/HeroSliderCombined";
import WelcomeTwo from "../../components/WelcomeSection/WelcomeTwo";
import CountersThree from "../../components/Counters/CountersThree";
import TabsIconSectionTwo from "../../components/Tabs/TabsIconSectionTwo";
import CTATwo from "../../components/CTA/CTATwo";
import FooterOne from "../../components/Footer/FooterOne";
// import OurTeam from "../../components/Team/OurTeamFour";

const MinimalDesign = ({ about, service, team }) => {
  const [toggler, setToggler] = useState(false);
  const handleToggler = () => {
    setToggler(!toggler);
  };

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <Loader>
      <FsLightbox
        toggler={toggler}
        sources={["https://www.youtube.com/watch?v=sU3FkzUKHXU"]}
      />
      <HeroSliderCombined data={dataMinimal} font="cardo-font" />
      <WelcomeTwo
        toggler={toggler}
        handleToggler={handleToggler}
        ref1={about}
        ref2={service}
      />
      <CountersThree type="wide" />
      {/* <OurTeam forwardRef={team} /> */}
      <TabsIconSectionTwo title="What We Offer" font="cardo-font" />
      <CTATwo
        title="Let's Work Together"
        tagline=""
        textButton="Let's talk"
        linkButton="/contact"
      >
        At WebChisel, we believe that great web design is a combination of
        creativity, technology, and strategy. We're here to help you bring your
        vision to life and create a website that sets you apart from the
        competition.
      </CTATwo>
      <FooterOne />
    </Loader>
  );
};

export default MinimalDesign;
